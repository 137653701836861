.home {
    .all-content {
        a {
            color: $font_lt_color;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .container {
            @media screen and (max-width: $grid-breakpoints-md) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .col {
            @media screen and (max-width: $grid-breakpoints-md) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .row {
            @media screen and (max-width: $grid-breakpoints-md) {
                margin-left: -10px;
                margin-right: -10px;
            }

        }
        .header {
            margin-top: 16px;
            color: $font_lt_color;
            .header-signin {
                font-weight: 500;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.71;
                color: $font_lt_color;
            }
            .header-logo {
                display: block;
                margin-top: 91px;
                text-decoration: none;
                @include clearfix;
                @media screen and (max-width: $grid-breakpoints-sm) {
                    max-width: 240px;
                    text-align: center;
                }
            }
            .header-logo-img {
                float: left;
                width: 60px;
                height: 60px;
                @media screen and (max-width: $grid-breakpoints-sm) {
                    float: none;
                }
            }
            .header-logo-text {
                float: left;
                margin-left: 10px;
                font-size: 30px;
                font-weight: 50;
                line-height: 2;
                @media screen and (max-width: $grid-breakpoints-sm) {
                    display: block;
                    margin-left: 0;
                    float: none;
                    clear: both;
                }
            }
            .header-slogan {
                position: relative;
                margin-top: 50px;
                margin-bottom: 6px;
                font-size: 56px;
                font-weight: 500;
                line-height: 1.21;
                @media screen and (max-width: $grid-breakpoints-md) {
                    margin-bottom: 47px;
                    font-size: 44px;
                    line-height: 1.14;
                }
                @media screen and (max-width: $grid-breakpoints-sm) {
                    font-size: 28px;
                    font-weight: 900;
                    line-height: 1.14;
                    letter-spacing: 0.2px;
                }
            }
            .header-text {
                margin-top: 15px;
                margin-bottom: 66px;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                @media screen and (max-width: $grid-breakpoints-md) {
                    font-size: 16px;
                }
            }
        }
        .line {
            width: 200px;
            height: 4px;
            border-radius: 2px;
        }
        .home-splashscreen {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: url('/pages/images/home-splashscreen.jpg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            &-fake {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-image:  linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.7));
                //background-color: rgba(0, 0, 0, 0.3);
            }
        }
        .home-content {
            margin-top: 50px;
            margin-bottom: 20px;
            align-items: stretch;
        }
        .home-content .col-4 {
            padding-left: 10px;
            padding-right: 10px;
        }
        .home-content .benefit {
            height: 100%;
            padding-bottom: 20px;
        }
        .home-content .benefit-body {
            height: 100%;
            padding: {
                top: 0;
                left: 20px;
                right: 20px;
                bottom: 18px;
            }
            height: 100%;
            border: none;
            border-radius: 4px;
        }
        .home-content .benefit-title {
            float: left;
            margin: 0;
            padding-top: 30px;
            max-width: 50%;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            text-transform: uppercase;
        }
        .home-content .benefit-text {
            padding-top: 15px;
            font-size: 14px;
            line-height: 1.29;
            clear: both;
        }
        .home-content .benefit-icon {
            float: right;
            margin-right: -20px;
        }
        .footer {
            position: relative;
            padding-top: 20px;
            padding-bottom: 54px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            .container {
                position: relative;
                z-index: 2;
            }
        }
        .language-switcher {
            float: right;
            .language-item {
                text-decoration: underline;
                cursor: pointer;
                &.is-active {
                    font-weight: bold;
                    text-decoration: none;
                    cursor: default;
                }
            }
        }
        .btn-lead {
            max-width: 300px;
            @media screen and (max-width: $grid-breakpoints-sm) {
                max-width: 100%;
                display: block;
                width: 100%;
            }
        }
        .btn-vk {
            background-color: $vk_color;
            color: $font_lt_color;
        }
        .quote {
            margin-bottom: 53px;
            .quote-icon {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
            }
            .quote-text {
                position: relative;
                font-size: 24px;
                font-weight: 300;
                line-height: 1.21;
            }
            .quote-author {
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 19px;
                line-height: 1.52;
            }
            .quote-pic {
                display: block;
                width: 120px;
                height: 120px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 50%;
            }
        }
        .copyright {
            margin-top: 50px;
        }
    }

    .modal {
        color: $absolutly_black_color;
        padding-right: 0!important;
        top: calc(50% - 150px) !important;

        .contacts-title {
            margin-bottom: 15px;
        }
        .submit-button-holder {
            display: flex;
            justify-content: center;

            .submit-button {
                padding-left: 25px;
                padding-right: 25px;
            }
        }

        .error {
            margin-bottom: 0px;
        }
    }
}