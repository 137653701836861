$bg_color: #343a40;
$absolutly_black_color: #000000;
$font_lt_color: #FFFFFF;
$main_color: #4A90E2;
$success_color: #5cb85c;
$primary_color: #4a90e2;
$info_color: #17a2b8;
$warning_color: #f5a623;
$danger_color: #d0021b;
$secondary_color: #c868dc;
$vk_color:#428bca;

$ff: 'Roboto', sans-serif;
$grid-breakpoints-sm: 576px;
$grid-breakpoints-md: 768px;
$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}