.notfound-wrapper-phone {
    width:300px!important;
    position:absolute!important;
    top:50%;
    margin-top:-136px;
    left:50%;
    margin-left:-150px;
}

.notfound-wrapper-desktop {
    width:600px!important;
    position:absolute!important;
    top:50%;
    margin-top:-123px;
    left:50%;
    margin-left:-300px;
}

.bad {
    color: red;
}

.bold {
    font-weight: bold;
}