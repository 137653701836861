html, body {
    background-color: $bg_color;
}
body {
    font-family: $ff;
    color: $font_lt_color;
}

body.modal-open .modal-blur {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
    filter: url("/pages/images/blur.svg#blur");
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
}

button {
    &:focus {
        outline: none;
    }
}
.btn {
    &-success {
        background-color: $success_color;
    }
}
.bg {
    &-primary {
        background-color: $primary_color !important;
    }
    &-success {
        background-color: $success_color !important;
    }
    &-warning {
        background-color: $warning_color !important;
    }
    &-secondary {
        background-color: $secondary_color !important;
    }
    &-warning {
        background-color: $warning_color !important;
    }
    &-danger {
        background-color: $danger_color !important;
    }
}
.form-group {
    position: relative;
}

/* Remove all shadows */
input {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}

textarea {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}

.well {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}

.panel {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.bootstrap-tagsinput {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.modal {
    overflow-y: auto;
}
.modal-open {
    overflow: hidden !important;
    padding-right: 0 !important;
}
.modal-content {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.modal-footer .btn+.btn {
    margin-left: 20px;
}
.btn {
    -webkit-box-shadow:none!important;
    box-shadow:none!important;
    .active {
        -webkit-box-shadow:none!important;
        box-shadow:none!important;
    }
}
.btn-mobile-full {
    @media screen and (max-width: 424px){
        margin-top: 15px;
        width: 100%;
        float: none;
        clear: both;
    }
}
.toggle-handle {
    border-width: 0px;
}